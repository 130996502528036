const ProductFilter = {
  root: null,
  buttons: null,
  select: null,

  init() {
    // Get elements
    this.root = document.getElementById('ProductFilter');
    // Cancel if root missing
    if (!this.root) {
      return;
    }
    this.body = document.querySelector('body');
    this.buttons = [...this.root.querySelectorAll('.ProductFilterBtn')];
    this.select = this.root.querySelector('select');
    // Cancel if any of these elements are missing
    if (!this.buttons || !this.select) {
      return;
    }

    this.btnClickListener = this.onFilterBtnClick.bind(this);
    this.buttons.forEach((btn) => {
      btn.addEventListener('click', this.btnClickListener);
    });

    this.selectChangeListener = this.onFilterSelectChange.bind(this);
    this.select.addEventListener('change', this.selectChangeListener);

  },

  onFilterBtnClick(event) {
    const btn = event.currentTarget;
    if(btn.classList.contains('disabled')) {
      alert('Coming soon');
      return;
    }
    this.removePrevFilter();
    const category = btn.getAttribute('data-filter');
    this.addFilter(category);
    btn.classList.add('selected');
  },

  onFilterSelectChange() {
    this.removePrevFilter();
    this.addFilter(this.select.value);
  },

  removePrevFilter() {
    this.body.classList.remove('show-bakoba-new');
    this.body.classList.remove('show-bakoba-classic');
    this.body.classList.remove('show-bakoba-education');
    this.buttons.forEach((btnEl) => {
      btnEl.classList.remove('selected');
    });
  },

  addFilter(category) {
    this.body.classList.add('show-bakoba-' + category);
  },

};

export default ProductFilter;