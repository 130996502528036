// Dependencies
import Glide from '@glidejs/glide'
import noScroll from 'no-scroll';
import axios from 'axios';

export class Product {

  constructor(root) {
    this.root = root;
    this.slider = root.querySelector('.Product__images');
    
    this.zoomModal = root.querySelector('.ZoomModal');
    this.zoomBtn = root.querySelector('.Product__images__zoom');
    this.zoomCloseBtn = root.querySelector('.ZoomModal__close');

    this.retailerModal = root.querySelector('.RetailerModal');
    this.retailerBtn = root.querySelector('.RetailerModal__open');
    this.retailerBackdrop = root.querySelector('.RetailerModal__backdrop');
    this.retailerCloseBtn = root.querySelector('.RetailerModal__close');

    this.productImages = [...this.root.querySelectorAll('.Product__images .glide__slides img')];

    this.productFilter = document.getElementById('ProductFilter');
    if(this.productFilter) {
      this.productFilterBtns = [...this.productFilter.querySelectorAll('.ProductFilterBtn')];
      this.productFilterSelect = this.productFilter.querySelector('select');
    }

    this.cart = document.getElementById('CartItems');
    this.cartTotal = document.getElementById('CartTotal');
    this.cartCheckbox = document.getElementById('Cart__checkbox');
    this.cartOverlay = document.getElementById('CartOverlay');
    this.addToCartText = document.getElementById('AddToCartText');

    setTimeout( () => {
      this.initialize();
    }, 200 );
    
  }

  initialize() {
    this.initCarousel();
    this.initAjax();

    if(this.zoomBtn && this.zoomModal && this.zoomCloseBtn) {
      this.zoomBtn.addEventListener('click', () => {
        this.zoomModal.classList.add('ZoomModal--open');
        setTimeout( () => {
          // Trigger resize to make slider in correct place
          window.dispatchEvent(new Event('resize'));
        }, 100 );
        noScroll.toggle();
      });
      this.zoomCloseBtn.addEventListener('click', () => {
        this.zoomModal.classList.remove('ZoomModal--open');
        noScroll.toggle();
      });
    }

    if(this.retailerBtn && this.retailerModal && this.retailerCloseBtn) {
      this.retailerBtn.addEventListener('click', () => {
        this.retailerModal.classList.add('RetailerModal--open');
        noScroll.toggle();
      });
      this.retailerBackdrop.addEventListener('click', () => {
        this.retailerModal.classList.remove('RetailerModal--open');
        noScroll.toggle();
      });
      this.retailerCloseBtn.addEventListener('click', () => {
        this.retailerModal.classList.remove('RetailerModal--open');
        noScroll.toggle();
      });
    }

    if(this.productFilter && this.productFilterBtns && this.productFilterSelect) {
      this.productFilterSelect.addEventListener('change', () => {
        setTimeout( () => {
          this.restartSlider();
        }, 300 );
      });
      this.productFilterBtns.forEach((btn) => {
        btn.addEventListener('click', () => {
          setTimeout( () => {
            this.restartSlider();
          }, 300 );
        });
      });
    }

    let windowWidth = document.documentElement.clientWidth;
    if ( windowWidth > 768 ) {
      this.productImages.map(image => {
        image.addEventListener('click', () => {
          this.zoomModal.classList.add('ZoomModal--open');
          noScroll.toggle();
        });
      });
    }
  }

  initCarousel() {
    const options = {
      perView: 1,
      gap: 0,
    }

    // Create sliders
    this.GlideSlider = new Glide(this.slider, options)
    this.GlideSliderZoom = new Glide(this.zoomModal, options)

    // Mount sliders
    this.GlideSlider.mount();
    this.GlideSliderZoom.mount();

    // Video
    this.video = this.slider.querySelector('video');
    this.videoModal = this.zoomModal.querySelector('video');
    
    // On move
    this.GlideSlider.on('move.after', (movement) => {
      // Keep zoom slider in sync with main slider
      this.GlideSliderZoom.go('=' + this.GlideSlider._i);
      // Keep zoom slider in sync with main slider
      if(this.video) {
        let activeEl = this.slider.querySelector('.glide__slide--active');
        if(activeEl.dataset.video) {
          this.video.play();
        } else {
          this.video.pause()
        }
      }
    })

    // On move zoom
    this.GlideSliderZoom.on('move.after', (movement) => {
      if(this.videoModal) {
        let activeEl = this.zoomModal.querySelector('.glide__slide--active');
        if(activeEl.dataset.video) {
          this.videoModal.play();
        } else {
          this.videoModal.pause()
        }
      }
    })
  }

  restartSlider() {
    this.GlideSlider.destroy();
    this.GlideSliderZoom.destroy();
    this.initCarousel();
  }

  initAjax() {
    let addToCartBtn = this.root.querySelector('.single_add_to_cart_button');
    let quantityField = this.root.querySelector('.qty');
    if (addToCartBtn) {
      addToCartBtn.addEventListener('click', (e) => {
        e.preventDefault();
        let productID = addToCartBtn.value;
        let qty = quantityField.value;
        this.addToCart(productID, qty, addToCartBtn);
      });
    }
  }

  addToCart(product, qty, btn) {
    // console.log('Add product to cart, product id: ', product);
    let btnText = btn.textContent;
    btn.textContent = this.addToCartText.textContent;
    btn.classList.add('button--adding-to-cart');
    btn.style.cursor = 'wait';

    axios.post(
      THEME.restURL + 'bakoba/v1/add-product-to-cart', {
        product_id: product,
        quantity: qty,
      }, {
        header: {
          'X-WP-Nonce': THEME.nonce,
        }
      }
    ).then( res => {
      //console.log(res.data);
      if (res.data && res.data.content) {
        this.cart.innerHTML = res.data.content;
        this.cartTotal.innerHTML = res.data.cart_total;
      }
    }).catch( error => {
      // Check if request was canceled
      if (axios.isCancel(error)) {
        console.log('First request canceled', error.message);
      } else if (error.response) {
        if (error.response.status === 404) {
          if (error.response.data && error.response.data.message) {
            this.cart.innerHTML = `<h2>${error.response.data.message}</h2>`;
          } else {
            this.cart.innerHTML = '<h2>An error has occured</h2>';
          }
        }
      } else {
        console.dir(error);
      }
    }).then( () => {
      // Always
      this.cartCheckbox.checked = true;
      noScroll.toggle();
      this.cartOverlay.classList.toggle('Cart__overlay--open');
      btn.textContent = btnText;
      btn.classList.remove('button--adding-to-cart');
      btn.style.cursor = 'auto';
    });
  }

}
