// Dependencies
import axios from 'axios';

export const Slidein = {
  root: null,
  closeBtn: null, 

  init(root) {
    // Get root
    this.root = root;
    this.id = this.root.getAttribute('id');
    this.closeBtn = this.root.querySelector('.Slidein__close');

    // Cancel if any of the elements is missing
    if (!this.root) {
      return;
    }

    // On scroll
    this.scrollListener = this.onScroll.bind(this);
    window.addEventListener('scroll', this.scrollListener);

    // Dismiss on close click
    if(this.closeBtn) {
      this.closeBtn.addEventListener('click', () => {
        this.dismissSlidein();
      });
    }
  },

  onScroll() {
    window.removeEventListener('scroll', this.scrollListener);
    setTimeout(() => {
      this.root.classList.add('Slidein--active');
    }, 2000 );
  },

  dismissSlidein() {
    // Store choice in cookie
    axios.post(
      THEME.restURL + 'bakoba/v1/dismiss-slidein', 
      {slideinID: this.id},
      {header: {'X-WP-Nonce': THEME.nonce}}
    ).then( res => {
      console.log('Stored cookie ', res);
    }).catch( error => {
      console.log('Error: ', error);
    });
    // Hide visually
    this.root.classList.remove('Slidein--active'); 
  },
  

};