// imports
import styles from '../css/app.scss';
import noScroll from 'no-scroll';
import throttle from 'lodash.throttle';
import zenscroll from 'zenscroll';

// Poly
import promisePolyfill from 'es6-promise/auto';
import svg4everybody from 'svg4everybody';
import picturefill from 'picturefill';

import Forms from './components/Forms';
import ProductFilter from './components/ProductFilter';
import { Product } from './components/Product';
import { Slidein } from './components/Slidein';

//
// App async loading modules
//
const main = async () => {
  //
  // Only load Cookie component if needed
  //
  const Cookie = document.getElementById('ConsentCookies');
  if(Cookie) {
    const { CookieConsent } = await import(/* webpackChunkName: "CookieConsent" */ './components/CookieConsent');
    CookieConsent.init();
  }

  //
  // Only load logo component if neede
  //
  const pageHasHero = document.querySelector('.page-has-hero');
  const elLogo = document.querySelector('.Header__logo__top');
  if(pageHasHero && elLogo) {
    const { Logo } = await import(/* webpackChunkName: "Logo" */ './components/Logo');
    new Logo(elLogo);
  }

  //
  // Only load Glide Slider js if needed.
  //
  let sliders = [...document.querySelectorAll('.glide')];
  if (sliders.length) {
    const { Slider } = await import(/* webpackChunkName: "Slider" */ './components/Sliders');
    sliders.forEach(function(slider) {
      new Slider(slider);
    });
  }
  //
  // Only load map js if needed
  //
  const storelocator = document.getElementById('Map');
  if(storelocator) {
    const { Map } = await import(/* webpackChunkName: "Map" */ './components/Map');
    Map.init();
  }
  //
  // Only guide selector js if needed
  //
  const Guide = document.getElementById('Guides');
  if(Guide) {
    const { Guides } = await import(/* webpackChunkName: "GuideSelector" */ './components/Guides');
    Guides.init();
  }
  //
  // Only load shapes rotation if needed
  //
  const ShapesPresent = [...document.querySelectorAll('.FloatingBrick')];
  if(ShapesPresent) {
    const { Shapes } = await import(/* webpackChunkName: "Shapes" */ './components/Shapes');
    new Shapes();
  }
};

//
// Application
//
const Bakoba = {

  // Options / globals
  bodyElement: null,
  burgerMenubtn: null,
  navCheckBox: null,
  cartBtn: null,
  cartCloseBtn: null,
  cartOverlay: null,
  cartChecbox: null,
  searchBtn: null,
  searchClose: null,
  heroVideoBtn: null,
  heroVideoClose: null,
  cookieConsentBtn: null,

  init () {
    // Execute async function
    main().then( () => {
      console.log('Modules loaded');
    });

    // Body
    this.bodyElement = document.getElementsByTagName('body')[0];

    // Burger menu noscroll toggle
    this.burgerMenubtn = document.getElementById('NavTrigger');
    this.navCheckBox = document.getElementById('Nav__checkbox');
    if (this.burgerMenubtn) {
      this.burgerMenubtn.addEventListener('click', this.toggleNoScroll);
    }

    // Close nav when clicking same page links
    const navLinks = [...document.querySelectorAll('.Nav a')];
    navLinks.forEach((link) => {
      if(link.href.includes("#")) {
        link.addEventListener('click', () => {
          setTimeout( () => {
            this.navCheckBox.checked = false;
            this.toggleNoScroll();
          }, 500 );
        });
      }
    });

    // Cart toggle
    this.cartBtn = document.getElementById('CartTrigger');
    this.cartCloseBtn = document.getElementById('CartClose');
    this.cartOverlay = document.getElementById('CartOverlay');
    this.cartChecbox = document.getElementById('Cart__checkbox');
    if (this.cartBtn && this.cartCloseBtn && this.cartOverlay && this.cartChecbox) {
      this.cartBtn.addEventListener('click', () => {
        this.toggleCart();
      });
      this.cartCloseBtn.addEventListener('click', () => {
        this.toggleCart();
      });
      this.cartOverlay.addEventListener('click', () => {
        this.toggleCart();
      });
    }

    // Search toggle
    this.searchBtn = document.getElementById('SearchTrigger');
    this.searchClose = document.getElementById('SearchClose');
    if (this.searchBtn && this.searchClose) {
      this.searchBtn.addEventListener('click', this.toggleNoScroll);
      this.searchClose.addEventListener('click', () => {
        noScroll.off();
      });
    }

    // Hero video 
    this.heroVideoOverlay = document.getElementById('HeroVideoModal');
    this.heroVideoBtn = document.getElementById('HeroVideoBtn');
    this.heroVideoClose = document.getElementById('HeroVideoClose');
    if (this.heroVideoBtn && this.heroVideoClose && this.heroVideoOverlay) {
      this.heroVideoBtn.addEventListener('click', this.toggleNoScroll);
      let video = document.getElementById('HeroVideo');
      this.heroVideoClose.addEventListener('click', () => {
        video.pause();
        noScroll.off();
      });
      this.heroVideoOverlay.addEventListener('click', () => {
        video.pause();
        noScroll.off();
      });
    }

    // Small washing machine effect
    if(document.getElementById('Washer')) {
      this.initWasher();
    }

    // Forms
    Forms.init();

    // Filter
    ProductFilter.init();

    // On scroll
    // window.addEventListener('scroll', throttle(this.onScroll, 1000 / 60));
    // window.addEventListener('scroll', throttle(this.scrollDelayed, 200));

    // Slideins
    [...document.querySelectorAll('.Slidein')].forEach(el => {
      Slidein.init(el);
    });

    // zenscroll setup (smooth scroll internal anchors)
    zenscroll.setup(null, 0)

    // Check for browser dark mode to show correct favicon
    if(window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
      document.getElementById('favicon-x16').href = "/favicon-16x16-light.png";
      document.getElementById('favicon-x32').href = "/favicon-32x32-light.png";
      document.getElementById('favicon-x96').href = "/favicon-96x96-light.png";
    }

    // Picturefill poly
    picturefill();
    // Svg poly
    svg4everybody();
  },

  initOnLoad () {
    // Wait for window to load before loading products (product sliders need it)
    [...document.querySelectorAll('.Product')].forEach(el => {
      new Product(el);
    });

    console.log('Made with ❤️❤️❤️ by Henrik Herland Boelsmand');
    console.log('https://hhb.dk');
  },

  onResize () {
    //console.log('Resize');
  },

  onScroll () {
    // console.log("Scroll");
  },

  scrollDelayed () {
    // scroll delayed
  },

  toggleCart () {
    this.toggleNoScroll();
    this.cartOverlay.classList.toggle('Cart__overlay--open');
  },

  toggleNoScroll () {
    noScroll.toggle();
  },

  initWasher() {
    const image = document.getElementById('Washer');
    window.onscroll = () => {
      image.style.transform = "rotate(" + window.pageYOffset/2 + "deg)";
    };
  },

};

document.addEventListener('DOMContentLoaded', () => Bakoba.init());
window.addEventListener('load', () => Bakoba.initOnLoad());
